import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Tags from '../components/tags';
import styles from '../stylesheets/article.module.css';

const ArticlesList = props => {
  const { data } = props;
  const posts = data.allMarkdownRemark.edges.filter(edge =>
    Boolean(edge.node.frontmatter.date),
  ); // You can filter your posts based on some criteria

  return (
    <Layout>
      <SEO title="Articles" />

      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        const tagsArray = node.frontmatter.tags || false;
        const readingTime = node.fields.readingTime.text;

        return (
          <div className={styles.list} key={node.fields.slug}>
            <article>
              <div className={styles.readingTime}>{readingTime}</div>
              <h1 className={styles.title}>
                <Link to={node.fields.slug}>{title}</Link>
              </h1>
              <p className={styles.excerpt}>
                {node.frontmatter.description || node.excerpt}
              </p>
              {tagsArray && <Tags array={tagsArray} />}
            </article>
          </div>
        );
      })}
    </Layout>
  );
};

export default ArticlesList;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
