import React from 'react';
import _ from 'lodash';
import styles from '../stylesheets/tags.module.css';
import { Link } from 'gatsby';

const Tags = ({ array }) => {
  return (
    <ul className={styles.taglist}>
      {array.map((tag, index) => {
        const path = `/articles/tags/${_.kebabCase(tag)}/`;
        return (
          <li className={styles.tag} key={index}>
            <Link className={styles.link} to={path}>
              {tag}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Tags;
